import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@/components/Button/Button';
import { Container } from '@/components/Container/Container';
import { AppRoute } from '@/config/routes';
import { useGithubAuth } from '@/hooks/useGithubAuth';
import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { UserEntity } from '@nerdbord/graphql-api';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { LogoIcon } from '../Icons/LogoIcon/LogoIcon';
import { FacebookIcon } from '../Icons/SocialIcon/FacebookIcon';
import { InstagramIcon } from '../Icons/SocialIcon/InstagramIcon';
import { LinkedinIcon } from '../Icons/SocialIcon/LinkedinIcon';
import { YoutubeIcon } from '../Icons/SocialIcon/YoutubeIcon';
import { GithubIcon } from '../Icons/TechnologiesIcon/GithubIcon';
import IconWrapper from '../IconWrapper/IconWrapper';
import styles from '../navigation/Navbar.module.scss';
import NavbarLinks from '../navigation/NavbarLinks';
import { Anchor } from '../UI/Anchor';

interface NavLink {
  href: string;
  title: string;
}

const socialsLinks = [
  {
    src: LinkedinIcon,
    href: 'https://www.linkedin.com/company/nerdbord/',
  },
  {
    src: YoutubeIcon,
    href: 'https://www.youtube.com/user/MrsDreadness',
  },
  { src: FacebookIcon, href: 'https://www.facebook.com/nerdbord/' },
  { src: InstagramIcon, href: 'https://www.instagram.com/nerdbord/' },
];

const cx = classNames.bind(styles);

interface NavbarProps {
  user: UserEntity | null;
}

const Navbar = (props: NavbarProps) => {
  const buttonRef = useRef(null);
  const asideRef = useRef(null);
  const [menuToggled, setmenuToggled] = useState(false);

  const { t } = useTranslation(I18nNamespaces.Homepage);

  const router = useRouter();

  const { handleAuthByGithub, isAuthorizing } = useGithubAuth();

  const navLinks: NavLink[] = useMemo(
    () => [
      { href: '#our-programs', title: t('navbar.our-programs') },
      { href: '#ourmethod', title: t('navbar.our-method') },
      { href: '#projects', title: t('navbar.projects') },
      { href: '#who-we-are', title: t('navbar.about-us') },
      { href: '#faq', title: t('navbar.faq') },
    ],
    [t]
  );

  const handleGithubButton = () => {
    !!props.user?.id
      ? router.push(AppRoute.board)
      : handleAuthByGithub();
  };

  const mobileMenuToggle = () => {
    setmenuToggled((prevState) => {
      return !prevState;
    });
  };

  const mobileMenuHide = () => {
    setmenuToggled(false);
  };

  return (
    <header className={styles.header}>
      <Container>
        <nav>
          <div className={styles.navigation}>
            <div className={cx('left-container')}>
              <Anchor href="/">
                <IconWrapper icon={LogoIcon} />
              </Anchor>
              <div className={cx(TypographyClasses.links)}>
                {navLinks.map((link, index) => (
                  <NavbarLinks
                    key={index}
                    onClick={mobileMenuHide}
                    href={AppRoute.home + link.href}
                    title={link.title}
                  />
                ))}
              </div>
            </div>

            <div className={cx('right-container')}>
              <Button
                variant={ButtonVariant.orange}
                size={ButtonSize.small}
                disabled={isAuthorizing}
                onClick={handleGithubButton}
                aria-label="Get started for free button"
              >
                {isAuthorizing
                  ? t('navbar.authorizing')
                  : !!props.user?.id
                  ? t('navbar.go-to-board')
                  : t('navbar.login-with-github')}
                {!props.user && (
                  <IconWrapper
                    icon={GithubIcon}
                    width={28}
                    height={28}
                    fill="white"
                  />
                )}
              </Button>
            </div>
          </div>

          <div className={cx('mobile-nav')}>
            <Anchor href="/">
              <IconWrapper icon={LogoIcon} className={cx('logo')} />
            </Anchor>

            <div className={cx('right-container')}>
              <Button
                disabled={isAuthorizing}
                onClick={handleAuthByGithub}
                variant={ButtonVariant.orange}
                size={ButtonSize.small}
              >
                {isAuthorizing
                  ? t('navbar.authorizing')
                  : !!props.user?.id
                  ? t('navbar.go-to-board')
                  : t('navbar.login-with-github')}
                {!props.user?.id && (
                  <IconWrapper
                    icon={GithubIcon}
                    width={28}
                    height={28}
                    fill="white"
                  />
                )}
              </Button>
              <button
                onClick={mobileMenuToggle}
                className={cx('menu')}
                aria-label={t('navbar.toggle-mobile-menu')}
              >
                <CSSTransition
                  nodeRef={buttonRef}
                  in={menuToggled}
                  timeout={500}
                  classNames={{
                    enterActive: cx('icon-active'),
                    enterDone: cx('icon-active'),
                  }}
                >
                  <div ref={buttonRef} className={cx('icon')}></div>
                </CSSTransition>
              </button>
            </div>

            <CSSTransition
              nodeRef={asideRef}
              in={menuToggled}
              timeout={500}
              classNames={{
                enterActive: cx('sidebar-active'),
                enterDone: cx('sidebar-active'),
              }}
            >
              <aside ref={asideRef} className={styles.sidebar}>
                <div className={cx(TypographyClasses.wrapper)}>
                  <div className={cx(TypographyClasses.links)}>
                    {navLinks.map((link, index) => (
                      <NavbarLinks
                        key={index}
                        onClick={mobileMenuHide}
                        href={AppRoute.home + link.href}
                        title={link.title}
                      />
                    ))}
                  </div>
                  <div className={cx(TypographyClasses.socials)}>
                    {socialsLinks.map((social, index) => (
                      <Anchor
                        key={index}
                        href={social.href}
                        onClick={mobileMenuHide}
                      >
                        <IconWrapper icon={social.src} />
                      </Anchor>
                    ))}
                  </div>
                </div>
              </aside>
            </CSSTransition>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Navbar;
