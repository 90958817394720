import { IconModel } from '@/landing/models/IconModel';

export const YoutubeIcon = ({ width, height, fill }: IconModel) => {
  const iconWidth = width ? width : 27;
  const iconHeight = height ? height : 18;
  const iconFill = fill ? fill : '#E4E4E7';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Nerdbord on YouTube</title>
      <desc>
        Subscribe to Nerdbord YouTube channel for informative videos,
        tutorials, and thought-provoking content. Join our community
        and explore the intersection of creativity and technology.
      </desc>
      <path
        d="M25.7344 2.85938C25.4531 1.73438 24.5625 0.84375 23.4844 0.5625C21.4688 0 13.5 0 13.5 0C13.5 0 5.48438 0 3.46875 0.5625C2.39062 0.84375 1.5 1.73438 1.21875 2.85938C0.65625 4.82812 0.65625 9.04688 0.65625 9.04688C0.65625 9.04688 0.65625 13.2188 1.21875 15.2344C1.5 16.3594 2.39062 17.2031 3.46875 17.4844C5.48438 18 13.5 18 13.5 18C13.5 18 21.4688 18 23.4844 17.4844C24.5625 17.2031 25.4531 16.3594 25.7344 15.2344C26.2969 13.2188 26.2969 9.04688 26.2969 9.04688C26.2969 9.04688 26.2969 4.82812 25.7344 2.85938ZM10.875 12.8438V5.25L17.5312 9.04688L10.875 12.8438Z"
        fill={iconFill}
      />
    </svg>
  );
};
