export enum TypographyClasses {
  wrapper = 'wrapper',
  container = 'container',
  title = 'title',
  subtitle = 'subtitle',
  header = 'header',
  subheader = 'subheader',
  content = 'content',
  emoji = 'emoji',
  color = 'color',
  button = 'button',
  links = 'links',
  socials = 'socials',
  link = 'link',
  img = 'img',
  footer = 'footer',
}
