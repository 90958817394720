import { IconModel } from '@/landing/models/IconModel';

export const LogoIcon = ({
  width,
  height,
  className,
  fill,
}: IconModel) => {
  const iconWidth = width ? width : 142;
  const iconHeight = height ? height : 22;
  const iconFill = fill ? fill : 'white';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 142 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Nerdbord</title>
      <desc>Nerdbord Logo</desc>
      <path
        d="M39.3899 0V20.7668H36.0437L26.2585 6.62144H26.0862V20.7668H22.3242V0H25.6907L35.4657 14.1555H35.6482V0H39.3899Z"
        fill={iconFill}
      />
      <path
        d="M48.7942 21.071C47.2327 21.071 45.8841 20.7465 44.7484 20.0975C43.6195 19.4418 42.7508 18.5157 42.1424 17.3192C41.534 16.1159 41.2298 14.6997 41.2298 13.0705C41.2298 11.4684 41.534 10.0623 42.1424 8.85224C42.7576 7.63544 43.6161 6.68904 44.718 6.01303C45.8198 5.33027 47.1144 4.98889 48.6016 4.98889C49.5615 4.98889 50.4674 5.14437 51.3191 5.45533C52.1776 5.75953 52.9348 6.23273 53.5905 6.87494C54.253 7.51714 54.7735 8.3351 55.152 9.32882C55.5306 10.3158 55.7199 11.492 55.7199 12.8575V13.9831H42.9536V11.5089H52.2013C52.1945 10.8059 52.0424 10.1806 51.745 9.63302C51.4476 9.0787 51.0318 8.64268 50.4978 8.32496C49.9705 8.00724 49.3553 7.84838 48.6523 7.84838C47.9019 7.84838 47.2428 8.0309 46.675 8.39594C46.1071 8.75422 45.6644 9.22742 45.3466 9.81554C45.0357 10.3969 44.8768 11.0357 44.8701 11.732V13.8918C44.8701 14.7977 45.0357 15.5751 45.3669 16.224C45.6982 16.8662 46.1612 17.3597 46.7561 17.7045C47.351 18.0425 48.0473 18.2115 48.8449 18.2115C49.379 18.2115 49.8623 18.1371 50.295 17.9884C50.7276 17.8329 51.1028 17.6065 51.4205 17.309C51.7382 17.0116 51.9782 16.6432 52.1405 16.2038L55.5678 16.5891C55.3515 17.4949 54.9391 18.2858 54.3307 18.9618C53.7291 19.6311 52.9584 20.1516 52.0188 20.5234C51.0791 20.8884 50.0043 21.071 48.7942 21.071Z"
        fill={iconFill}
      />
      <path
        d="M57.3976 20.7668V5.19169H60.9567V7.78754H61.1189C61.4029 6.88846 61.8896 6.19556 62.5791 5.70883C63.2754 5.21535 64.0697 4.96861 64.962 4.96861C65.1648 4.96861 65.3913 4.97875 65.6414 4.99903C65.8983 5.01255 66.1112 5.03621 66.2802 5.07001V8.44664C66.1247 8.39256 65.878 8.34524 65.54 8.30468C65.2088 8.25736 64.8876 8.2337 64.5767 8.2337C63.9074 8.2337 63.3058 8.37904 62.7718 8.66972C62.2445 8.95364 61.8287 9.3491 61.5245 9.8561C61.2203 10.3631 61.0682 10.9478 61.0682 11.6103V20.7668H57.3976Z"
        fill={iconFill}
      />
      <path
        d="M72.4296 21.0406C71.2061 21.0406 70.1109 20.7262 69.1443 20.0975C68.1776 19.4688 67.4137 18.5562 66.8526 17.3597C66.2915 16.1632 66.011 14.7098 66.011 12.9995C66.011 11.2689 66.2949 9.80878 66.8628 8.61902C67.4374 7.4225 68.2114 6.52004 69.1848 5.91163C70.1583 5.29647 71.2432 4.98889 72.4398 4.98889C73.3524 4.98889 74.1027 5.14437 74.6909 5.45533C75.279 5.75953 75.7454 6.12795 76.0902 6.5606C76.4349 6.98648 76.702 7.3887 76.8912 7.76726H77.0433V0H80.7242V20.7668H77.1143V18.3129H76.8912C76.702 18.6914 76.4282 19.0937 76.0699 19.5195C75.7116 19.9387 75.2384 20.2969 74.6503 20.5944C74.0622 20.8918 73.322 21.0406 72.4296 21.0406ZM73.4538 18.029C74.2312 18.029 74.8937 17.8194 75.4412 17.4003C75.9888 16.9744 76.4045 16.3829 76.6884 15.6258C76.9724 14.8687 77.1143 13.9865 77.1143 12.9792C77.1143 11.972 76.9724 11.0966 76.6884 10.353C76.4113 9.60936 75.9989 9.03138 75.4514 8.61902C74.9106 8.20666 74.2447 8.00048 73.4538 8.00048C72.6358 8.00048 71.953 8.21342 71.4055 8.6393C70.8579 9.06518 70.4456 9.6533 70.1684 10.4037C69.8912 11.154 69.7527 12.0125 69.7527 12.9792C69.7527 13.9527 69.8912 14.8213 70.1684 15.5852C70.4523 16.3423 70.8681 16.9406 71.4156 17.38C71.9699 17.8126 72.6493 18.029 73.4538 18.029Z"
        fill={iconFill}
      />
      <path
        d="M83.3702 20.7668V0H87.0409V7.76726H87.193C87.3823 7.3887 87.6493 6.98648 87.9941 6.5606C88.3388 6.12795 88.8053 5.75953 89.3934 5.45533C89.9815 5.14437 90.7319 4.98889 91.6445 4.98889C92.8477 4.98889 93.9327 5.29647 94.8994 5.91163C95.8729 6.52004 96.6435 7.4225 97.2113 8.61902C97.7859 9.80878 98.0732 11.2689 98.0732 12.9995C98.0732 14.7098 97.7927 16.1632 97.2316 17.3597C96.6705 18.5562 95.9067 19.4688 94.94 20.0975C93.9733 20.7262 92.8782 21.0406 91.6546 21.0406C90.7623 21.0406 90.0221 20.8918 89.4339 20.5944C88.8458 20.2969 88.3726 19.9387 88.0143 19.5195C87.6628 19.0937 87.389 18.6914 87.193 18.3129H86.9801V20.7668H83.3702ZM86.9699 12.9792C86.9699 13.9865 87.1119 14.8687 87.3958 15.6258C87.6865 16.3829 88.1022 16.9744 88.643 17.4003C89.1906 17.8194 89.8531 18.029 90.6305 18.029C91.4417 18.029 92.121 17.8126 92.6686 17.38C93.2162 16.9406 93.6285 16.3423 93.9057 15.5852C94.1896 14.8213 94.3316 13.9527 94.3316 12.9792C94.3316 12.0125 94.193 11.154 93.9158 10.4037C93.6387 9.6533 93.2263 9.06518 92.6787 8.6393C92.1312 8.21342 91.4484 8.00048 90.6305 8.00048C89.8463 8.00048 89.1804 8.20666 88.6329 8.61902C88.0853 9.03138 87.6696 9.60936 87.3857 10.353C87.1085 11.0966 86.9699 11.972 86.9699 12.9792Z"
        fill={iconFill}
      />
      <path
        d="M106.56 21.071C105.039 21.071 103.721 20.7363 102.605 20.0671C101.49 19.3979 100.625 18.4616 100.009 17.2583C99.4011 16.055 99.0969 14.649 99.0969 13.0401C99.0969 11.4312 99.4011 10.0217 100.009 8.81168C100.625 7.60164 101.49 6.662 102.605 5.99275C103.721 5.32351 105.039 4.98889 106.56 4.98889C108.081 4.98889 109.399 5.32351 110.515 5.99275C111.63 6.662 112.492 7.60164 113.1 8.81168C113.715 10.0217 114.023 11.4312 114.023 13.0401C114.023 14.649 113.715 16.055 113.1 17.2583C112.492 18.4616 111.63 19.3979 110.515 20.0671C109.399 20.7363 108.081 21.071 106.56 21.071ZM106.58 18.1304C107.405 18.1304 108.094 17.9039 108.649 17.451C109.203 16.9913 109.615 16.3761 109.886 15.6055C110.163 14.8349 110.302 13.9763 110.302 13.0299C110.302 12.0768 110.163 11.2149 109.886 10.4442C109.615 9.66682 109.203 9.04828 108.649 8.5886C108.094 8.12892 107.405 7.89908 106.58 7.89908C105.735 7.89908 105.032 8.12892 104.471 8.5886C103.917 9.04828 103.501 9.66682 103.224 10.4442C102.953 11.2149 102.818 12.0768 102.818 13.0299C102.818 13.9763 102.953 14.8349 103.224 15.6055C103.501 16.3761 103.917 16.9913 104.471 17.451C105.032 17.9039 105.735 18.1304 106.58 18.1304Z"
        fill={iconFill}
      />
      <path
        d="M115.711 20.7668V5.19169H119.27V7.78754H119.432C119.716 6.88846 120.203 6.19556 120.892 5.70883C121.589 5.21535 122.383 4.96861 123.275 4.96861C123.478 4.96861 123.705 4.97875 123.955 4.99903C124.212 5.01255 124.424 5.03621 124.593 5.07001V8.44664C124.438 8.39256 124.191 8.34524 123.853 8.30468C123.522 8.25736 123.201 8.2337 122.89 8.2337C122.221 8.2337 121.619 8.37904 121.085 8.66972C120.558 8.95364 120.142 9.3491 119.838 9.8561C119.534 10.3631 119.381 10.9478 119.381 11.6103V20.7668H115.711Z"
        fill={iconFill}
      />
      <path
        d="M130.743 21.0406C129.519 21.0406 128.424 20.7262 127.458 20.0975C126.491 19.4688 125.727 18.5562 125.166 17.3597C124.605 16.1632 124.324 14.7098 124.324 12.9995C124.324 11.2689 124.608 9.80878 125.176 8.61902C125.751 7.4225 126.525 6.52004 127.498 5.91163C128.472 5.29647 129.556 4.98889 130.753 4.98889C131.666 4.98889 132.416 5.14437 133.004 5.45533C133.592 5.75953 134.059 6.12795 134.403 6.5606C134.748 6.98648 135.015 7.3887 135.204 7.76726H135.357V0H139.037V20.7668H135.428V18.3129H135.204C135.015 18.6914 134.741 19.0937 134.383 19.5195C134.025 19.9387 133.552 20.2969 132.964 20.5944C132.375 20.8918 131.635 21.0406 130.743 21.0406ZM131.767 18.029C132.544 18.029 133.207 17.8194 133.754 17.4003C134.302 16.9744 134.718 16.3829 135.002 15.6258C135.286 14.8687 135.428 13.9865 135.428 12.9792C135.428 11.972 135.286 11.0966 135.002 10.353C134.725 9.60936 134.312 9.03138 133.765 8.61902C133.224 8.20666 132.558 8.00048 131.767 8.00048C130.949 8.00048 130.266 8.21342 129.719 8.6393C129.171 9.06518 128.759 9.6533 128.482 10.4037C128.204 11.154 128.066 12.0125 128.066 12.9792C128.066 13.9527 128.204 14.8213 128.482 15.5852C128.766 16.3423 129.181 16.9406 129.729 17.38C130.283 17.8126 130.963 18.029 131.767 18.029Z"
        fill={iconFill}
      />
      <path
        d="M9.7019 0.950377C9.7019 0.579749 10.0042 0.279297 10.3772 0.279297H10.712C14.4414 0.279297 17.4647 3.28383 17.4647 6.9901V7.37104C17.4647 7.74167 17.1624 8.04212 16.7895 8.04212H10.3772C10.0042 8.04212 9.7019 7.74167 9.7019 7.37104V0.950377Z"
        fill="#FB923C"
      />
      <path
        d="M9.7019 10.3999C9.7019 10.0148 10.0042 9.70264 10.3772 9.70264H16.7895C17.1624 9.70264 17.4647 10.0148 17.4647 10.3999V20.0952C17.4647 20.4802 17.1624 20.7924 16.7895 20.7924H10.3772C10.0042 20.7924 9.7019 20.4802 9.7019 20.0952V10.3999Z"
        fill="#EA580C"
      />
      <path
        d="M0.278931 0.98725C0.278931 0.596258 0.581262 0.279297 0.954206 0.279297H7.36648C7.73942 0.279297 8.04175 0.596258 8.04175 0.98725V12.879C8.04175 13.27 7.73942 13.587 7.36648 13.587H0.954206C0.581262 13.587 0.278931 13.27 0.278931 12.879V0.98725Z"
        fill="#FB923C"
      />
      <path
        d="M0.278931 15.8862C0.278931 15.5332 0.581262 15.2471 0.954206 15.2471H7.36648C7.73942 15.2471 8.04175 15.5332 8.04175 15.8862V20.1528C8.04175 20.5058 7.73942 20.7919 7.36648 20.7919H0.954206C0.581262 20.7919 0.278931 20.5058 0.278931 20.1528V15.8862Z"
        fill="#EA580C"
      />
    </svg>
  );
};
