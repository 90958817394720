import { Anchor } from '../UI/Anchor';

type NavLinkProps = {
  title: string;
  href: string;
  onClick?: () => void;
};

const NavLink = ({ title, href, onClick }: NavLinkProps) => {
  return (
    <Anchor onClick={onClick} href={href}>
      {title}
    </Anchor>
  );
};

export default NavLink;
