import { IconModel } from '@/landing/models/IconModel';

export const GithubIcon = ({ width, height, fill }: IconModel) => {
  const iconWidth = width ? width : 88;
  const iconHeight = height ? height : 72;
  const iconFill = fill ? fill : '#7B7B7B';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 88 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>GitHub Collaboration with Nerdbord</title>
      <desc>
        Explore collaborative software development using GitHub with
        Nerdbord. Discover version control, pull requests, and
        collaboration workflows to streamline your coding projects.
      </desc>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 13.7778C31.845 13.7778 22 23.6228 22 35.7778C22 45.5128 28.2975 53.7353 37.0425 56.6503C38.1425 56.8428 38.555 56.1828 38.555 55.6053C38.555 55.0828 38.5275 53.3503 38.5275 51.5078C33 52.5253 31.57 50.1603 31.13 48.9228C30.8825 48.2903 29.81 46.3378 28.875 45.8153C28.105 45.4028 27.005 44.3853 28.8475 44.3578C30.58 44.3303 31.8175 45.9528 32.23 46.6128C34.21 49.9403 37.3725 49.0053 38.6375 48.4278C38.83 46.9978 39.4075 46.0353 40.04 45.4853C35.145 44.9353 30.03 43.0378 30.03 34.6228C30.03 32.2303 30.8825 30.2503 32.285 28.7103C32.065 28.1603 31.295 25.9053 32.505 22.8803C32.505 22.8803 34.3475 22.3028 38.555 25.1353C40.315 24.6403 42.185 24.3928 44.055 24.3928C45.925 24.3928 47.795 24.6403 49.555 25.1353C53.7625 22.2753 55.605 22.8803 55.605 22.8803C56.815 25.9053 56.045 28.1603 55.825 28.7103C57.2275 30.2503 58.08 32.2028 58.08 34.6228C58.08 43.0653 52.9375 44.9353 48.0425 45.4853C48.84 46.1728 49.5275 47.4928 49.5275 49.5553C49.5275 52.4978 49.5 54.8628 49.5 55.6053C49.5 56.1828 49.9125 56.8703 51.0125 56.6503C59.7025 53.7353 66 45.4853 66 35.7778C66 23.6228 56.155 13.7778 44 13.7778Z"
        fill={iconFill}
      />
    </svg>
  );
};
