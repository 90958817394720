import { IconModel } from '@/landing/models/IconModel';

export const FacebookIcon = ({ width, height, fill }: IconModel) => {
  const iconWidth = width ? width : 24;
  const iconHeight = height ? height : 24;
  const iconFill = fill ? fill : '#E4E4E7';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Nerdbord on Facebook</title>
      <desc>
        Connect with Nerdbord on Facebook for the latest updates,
        news, and insights. Join our community and stay informed about
        our innovative solutions.
      </desc>
      <path
        d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8125 4.59375 22.6406 10.1719 23.4844V15.375H7.21875V12H10.1719V9.46875C10.1719 6.5625 11.9062 4.92188 14.5312 4.92188C15.8438 4.92188 17.1562 5.15625 17.1562 5.15625V8.01562H15.7031C14.25 8.01562 13.7812 8.90625 13.7812 9.84375V12H17.0156L16.5 15.375H13.7812V23.4844C19.3594 22.6406 23.625 17.8125 23.625 12Z"
        fill={iconFill}
      />
    </svg>
  );
};
