import { AppRoute } from '@/config/routes';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const useGithubAuth = () => {
  const [isAuthorizing, setAuthorizing] = useState(false);
  const { push } = useRouter();

  const handleAuthByGithub = async () => {
    try {
      if (
        !process.env.NEXT_PUBLIC_DEFAULT_GROUP_ID &&
        process.env.NODE_ENV !== 'development'
      ) {
        throw Error('Demo group is not set');
      }
      setAuthorizing(true);
      await push({
        pathname: AppRoute.auth.githubOauth,
        query: { groupId: process.env.NEXT_PUBLIC_DEFAULT_GROUP_ID },
      });
      setAuthorizing(false);
    } catch (err) {
      toast(err.message, { type: 'error' });
      setAuthorizing(false);
    }
  };

  const handleAuthToSelectedGroup = async (groupId: string) => {
    try {
      setAuthorizing(true);
      await push({
        pathname: AppRoute.auth.githubOauth,
        query: { groupId },
      });
      setAuthorizing(false);
    } catch (err) {
      toast(err.message, { type: 'error' });
      setAuthorizing(false);
    }
  };

  const handleConnectCustomerEmailByGithubToGroup = async (
    customerEmail: string,
    groupId: string
  ) => {
    try {
      setAuthorizing(true);
      await push({
        pathname: AppRoute.auth.githubOauth,
        query: { groupId, customerEmail },
      });
      setAuthorizing(false);
    } catch (err) {
      toast(err.message, { type: 'error' });
      setAuthorizing(false);
    }
  };

  return {
    handleAuthByGithub,
    handleAuthToSelectedGroup,
    handleConnectCustomerEmailByGithubToGroup,
    isAuthorizing,
  };
};
