import { IconModel } from '@/landing/models/IconModel';

export const LinkedinIcon = ({ width, height, fill }: IconModel) => {
  const iconWidth = width ? width : 21;
  const iconHeight = height ? height : 22;
  const iconFill = fill ? fill : '#E4E4E7';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Nerdbord on LinkedIn</title>
      <desc>
        Connect and engage with Nerdbord on LinkedIn. Explore our
        professional network, industry expertise, and career
        opportunities in the exciting realm of design and technology
      </desc>
      <path
        d="M19.5 0.5H1.45312C0.65625 0.5 0 1.20312 0 2.04688V20C0 20.8438 0.65625 21.5 1.45312 21.5H19.5C20.2969 21.5 21 20.8438 21 20V2.04688C21 1.20312 20.2969 0.5 19.5 0.5ZM6.32812 18.5H3.23438V8.51562H6.32812V18.5ZM4.78125 7.10938C3.75 7.10938 2.95312 6.3125 2.95312 5.32812C2.95312 4.34375 3.75 3.5 4.78125 3.5C5.76562 3.5 6.5625 4.34375 6.5625 5.32812C6.5625 6.3125 5.76562 7.10938 4.78125 7.10938ZM18 18.5H14.8594V13.625C14.8594 12.5 14.8594 11 13.2656 11C11.625 11 11.3906 12.2656 11.3906 13.5781V18.5H8.29688V8.51562H11.25V9.875H11.2969C11.7188 9.07812 12.75 8.23438 14.25 8.23438C17.3906 8.23438 18 10.3438 18 13.0156V18.5Z"
        fill={iconFill}
      />
    </svg>
  );
};
