import { AppRoute } from '@/config/routes';
import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { LogoIcon } from '../../Icons/LogoIcon/LogoIcon';
import { DiscordDark } from '../../Icons/SocialIcon/DiscordDark';
import { FacebookIcon } from '../../Icons/SocialIcon/FacebookIcon';
import { LinkedinIcon } from '../../Icons/SocialIcon/LinkedinIcon';
import { YoutubeIcon } from '../../Icons/SocialIcon/YoutubeIcon';
import IconWrapper from '../../IconWrapper/IconWrapper';
import NavbarLinks from '../../navigation/NavbarLinks';
import { Anchor } from '../../UI/Anchor';
import styles from './Footer.module.scss';

interface FooterLink {
  href: string;
  title: string;
}

const socialsLinks = [
  {
    src: DiscordDark,
    title: 'Discord',
    href: 'https://discord.com/invite/W7UJVJGD7Y',
  },
  {
    src: LinkedinIcon,
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/company/nerdbord/',
  },
  {
    src: YoutubeIcon,
    title: 'YouTube',
    href: 'https://www.youtube.com/user/MrsDreadness',
  },
  {
    src: FacebookIcon,
    title: 'Facebook',
    href: 'https://www.facebook.com/nerdbord/',
  },
];

const cx = classNames.bind(styles);

const Footer = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const nerdbordLinks: FooterLink[] = useMemo(
    () => [
      {
        href: '#our-programs',
        title: t('navbar.our-programs'),
      },
      {
        href: '#ourmethod',
        title: t('component.footer.nerdbord.ourMethod'),
      },
      {
        href: '#projects',
        title: t('component.footer.nerdbord.projects'),
      },
      { href: '#faq', title: t('component.footer.nerdbord.faq') },
    ],
    []
  );

  const documentLinks: FooterLink[] = useMemo(
    () => [
      {
        href: '/docs/privacy-policy.pdf',
        title: t('component.footer.documents.privacyPolicy'),
      },
      {
        href: '/docs/terms-conditions.pdf',
        title: t('component.footer.documents.termsOfService'),
      },
    ],
    []
  );

  return (
    <section className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={cx(`${TypographyClasses.container}-top`)}>
          <div className={cx(`${TypographyClasses.container}-links`)}>
            <div className={cx('nerdbord')}>
              <p className={cx('subheader')}>
                {t('component.footer.subheader.nerdbord')}
              </p>
              <div className={cx(TypographyClasses.links)}>
                {nerdbordLinks.map((link, index) => (
                  <NavbarLinks
                    key={index}
                    href={AppRoute.home + link.href}
                    title={t(link.title)}
                  />
                ))}
              </div>
            </div>
            <div className={cx('documents')}>
              <p className={cx('subheader')}>
                {t('component.footer.subheader.documents')}
              </p>
              <div className={cx(TypographyClasses.links)}>
                {documentLinks.map((link, index) => (
                  <NavbarLinks
                    key={index}
                    href={link.href}
                    title={t(link.title)}
                  />
                ))}
              </div>
            </div>
            <div className={cx('contact')}>
              <p className={cx('subheader')}>
                {t('component.footer.subheader.followUsOn')}
              </p>
              <div className={cx(TypographyClasses.socials)}>
                {socialsLinks.map((social, index) => (
                  <Anchor key={index} href={social.href}>
                    <div className={cx('link')}>
                      <IconWrapper
                        icon={social.src}
                        fill="#52525B"
                        width={18}
                        height={18}
                      />
                      <p className={cx('name')}>{social.title}</p>
                    </div>
                  </Anchor>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={cx(`${TypographyClasses.container}-bottom`)}>
          <div className={cx('left')}>
            <IconWrapper icon={LogoIcon} fill="#000" />
            <p className={cx('copyright')}>
              {t('component.footer.copyRight', {
                year: new Date().getFullYear(),
              })}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
