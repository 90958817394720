import React from 'react';

interface IconWrapperProps {
  icon: React.ElementType;
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const IconWrapper = ({
  icon: Icon,
  width,
  height,
  fill,
  className,
  ...restProps
}: IconWrapperProps) => {
  return (
    <Icon
      icon={Icon}
      width={width}
      height={height}
      fill={fill}
      className={className ? className : ''}
      {...restProps}
    />
  );
};

export default IconWrapper;
