import Link, { LinkProps } from 'next/link';
import { PropsWithChildren } from 'react';
import styles from './Anchor.module.scss';

interface AnchorProps extends LinkProps {
  children: React.ReactNode;
  className?: string;
  target?: string;
}

export const Anchor = ({
  children,
  className,
  ...props
}: PropsWithChildren<AnchorProps>) => {
  return (
    <Link {...props} className={styles.link}>
      {children}
    </Link>
  );
};
